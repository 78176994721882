import Api from "./Api";

export const GetVendorRevenueData = async (payload) =>
  Api().post(`revenue/getVendorAllRevenue`, payload);
export const GetVendorAdditionalRevenueData = async (payload) =>
  Api().post(`revenue/getVendorAdditionalRevenue`, payload);

export const DeleteRevenueById = async (payload) =>
  Api().post(`revenue/deleteRevenueItems`, payload);
export const GetMoviesVendor = async (payload) =>
  Api().post(`revenue/getMoviesVendor`, payload);
export const AddExpenseData = async (payload) =>
  Api().post(`revenue/addExpense`, payload);
export const GetExpenseData = async (payload) =>
  Api().get(`revenue/getExpenses`, {
    params: { ...payload },
  });
export const DeleteExpense = async (payload) =>
  Api().post(`revenue/deleteExpense`, payload);
export const GenerateRevenueInvoicePdf = async (payload) =>
  Api().post(`revenue/generateRevenueInvoicePdf`, payload);

export const GetAllInvoice = async (payload) =>
  Api().post(`revenue/getAllInvoice`, payload);

export const SendInvoiceReminder = async (payload) =>
  Api().post(`revenue/sendInvoiceReminder`, payload);

export const GetLicensorInvoiceNumbers = async (payload) =>
  Api().post(`revenue/getLicensorInvoiceNumbers`, payload);
export const GetRoyaltyReportList = async (payload) =>
  Api().post(`revenue/getRoyaltyReportList`, payload);

export const GetVendorMovies = async (payload) =>
  Api().post(`revenue/getVendorMovies`, payload);
export const GenerateRoyaltyReportPDF = async (payload) =>
  Api().post(`revenue/generateRoyaltyReportPdfFile`, payload);
export const GetRoyaltyReportDataMultiple = async (payload) =>
  Api().post(`revenue/fetchRoyaltyReportDataMultiple`, payload);
export const GetRoyaltyReportDataTerritory = async (payload) =>
  Api().post(`revenue/fetchRoyaltyReportDataTerritory`, payload);

export const GetRevenueInvoiceById = async (payload) =>
  Api().post(`revenue/getRevenueInvoiceById`, payload);
export const SavePaymentHistory = async (payload) =>
  Api().post(`revenue/savePaymentHistory`, payload);
export const GetRoyaltyReportById = async (payload) =>
  Api().post(`revenue/getRoyaltyReportById`, payload);
export const SendInvoiceEmail = async (payload) =>
  Api().post(`revenue/sendInvoiceEmail`, payload);
export const VerifyManualInvoiceItems = async (payload) =>
  Api().post(`revenue/verifyManualInvoiceItems`, payload);

export const GetAllPendingRoyaltyReportContract = async (params) =>
  Api().get(`revenue/getAllPendingRoyaltyReportContract`, { params });

export const getAllInvoiceAccounts = async (params) =>
  Api().get(`revenue/invoice-accounts`, { params });

export const GetAllBankPayments = async (params) =>
  Api().get(`revenue/bank-payments`, { params });

export const MarkInvoiceStornoCanceled = async (payload) =>
  Api().post(`revenue/markInvoiceStornoCanceled`, payload);

export const DeletePaymentItem = async (payload) =>
  Api().post(`revenue/deleteInvoicePaymentItem`, payload);

//old from here

//Manual Revenue to be removed once history data gets added
export const AddManualRevenueData = async (payload) =>
  Api().post(`revenue/manualRevenue`, payload);
export const getDataManualRevenue = async (payload) =>
  Api().post(`revenue/getDataManualRevenue`, payload);

//remove till here
